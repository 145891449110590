<template>
    <div>
        <el-dialog title="收貨地址" :visible.sync="dialogFormVisible" class="dlg-address"
                   :before-close="cancelDialog"
                   @open="openDialog()"
        >
            <el-form :model="addressForm" status-icon :rules="rulesAddress"
                     ref="ruleAddressForm" key="ruleAddressForm">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                    <el-input size="small" v-model="addressForm.realName" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="电话" :label-width="formLabelWidth" prop="mobile">
                    <el-input size="small" v-model="addressForm.phone" style="width: 80%"
                              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="地址" :label-width="formLabelWidth" prop="address">
                    <el-input size="small" v-model="addressForm.storeAddress" autocomplete="off" style="width: 95%"></el-input>
                </el-form-item>
                <el-form-item label="設為默認">
                    <el-switch v-model="addressForm.isDefault"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelDialog">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm('ruleAddressForm')">確 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {SaveAddress} from '@/request/api.js';

    export default {
        props:['dialogFormVisible','editRow'],
        name: '',
        components: {},
        data() {
            var inputRequired = (rule, value, callback) => {
                if (!value || value === '') {
                    callback(new Error('必填'));
                }
                callback();
            };
            var checkMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('請輸入正確的手機號'));
                }
                setTimeout(() => {
                    let mobile = /^(0\d{9,10})$/;
                    if (mobile.test(value) || value == '') {
                        callback();
                    } else {
                        callback(new Error('請輸入正確的手機號'));
                    }
                }, 500);
            };
            return {
                addressForm: {
                    id: 0,
                    realName: '',
                    phone: '',
                    storeAddress: '',
                    isDefault: false,
                },
                formLabelWidth: '80px',
                rulesAddress: {
                    realName: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, type: 'tel', validator: checkMobile, trigger: 'blur'}
                    ],
                    storeAddress: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            cancelDialog() {//右上角关闭窗口
                this.$emit('dialogVisible', false);
                this.$emit('resetEditRow');
            },
            openDialog(){//打开窗口时
                if (!this.editRow) {
                    setTimeout(()=>{
                        this.$refs['ruleAddressForm'].resetFields();
                    },100)

                }
                if (this.editRow) {
                    this.editAddress(this.editRow);
                }
            },
            closeDialog(){
                /*console.log('close dialog....');
                this.$refs['ruleAddressForm'].resetFields();
                this.$emit('dialogVisible', false);*/
            },
            submitForm(formName) {
                let _this = this;
                _this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        _this.$message.error('請輸入正確的資料');
                        return false;
                    }
                    SaveAddress({
                        id: this.addressForm.id,
                        realName: this.addressForm.realName,
                        phone: this.addressForm.phone,
                        storeAddress: this.addressForm.storeAddress,
                        isDefault: this.addressForm.isDefault,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$emit('dialogVisible', false);
                            _this.$refs['ruleAddressForm'].resetFields();
                            this.$message.success("更新成功！");
                            this.$emit('refreshAddressList');//刷新父窗口的地址数据列表 this.getAddressList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error('網絡請求失敗，稍候請重試!');
                    });

                });
            },
            editAddress(item) {
                this.addressForm.id = item.id;
                this.addressForm.realName = item.realName;
                this.addressForm.phone = item.phone;
                this.addressForm.storeAddress = item.storeAddress;
                this.addressForm.isDefault = item.isDefault;
            },

        },
        created() {
        },
        mounted() {
        },
    }
</script>
<style lang="less" scoped>
    /deep/ .el-dialog {
        width: 45%;
        @media screen and (max-width: 768px) {
            width: 80%;
            top: -30px;
        }

        .el-form-item {
            margin-bottom: 15px;
        }
    }

    /deep/ .el-button--mini {
        padding: 7px 5px;
    }

    .content {
        font-size: 14px;
    }
</style>
