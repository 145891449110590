<template>
    <div class="content">
        <div v-if="addressList.length>0">
            <el-row style="margin-bottom: 20px">下列的地址將會在您結帳時自動使用，但您還是可以在結帳時修改成其它地址。</el-row>
            <el-row>
                <el-button type="info" plain size="mini" @click="addAddress" align="right">新增</el-button>
            </el-row>
            <el-divider></el-divider>
            <el-row style="margin-bottom: 10px;" v-for="item in addressList" :key="item.id" type="flex" justify="space-between">
                <el-col :lg="16" :xs="17">
                    <el-row style="display: flex;justify-content: start">
                        <div style="display: flex;align-items: center;margin-right: 10px;">
                            <el-tag type="warning" size="mini">{{item.cnno}}</el-tag>
                        </div>
                        <div>
                            <div>
                                {{item.storeNo}} {{item.storeName}} {{item.storeAddress}}
                                <el-tag v-show="item.isDefault" type="danger" size="mini">默認</el-tag>
                            </div>
                            <div>
                                <el-tag type="info" size="mini">{{item.realName}}</el-tag>
                                <el-tag type="info" size="mini">{{item.phone}}</el-tag>
                            </div>
                        </div>
                    </el-row>

                </el-col>
                <el-col :lg="8" :xs="7">
                    <el-button size="mini" type="warning" @click="editAddress(item)">修改</el-button>
                    <el-button size="mini" type="danger" @click="delAddress(item)">删除</el-button>
                </el-col>
            </el-row>
        </div>
        <div v-show="emptFlg">
            <el-empty :image-size="100" description="您還沒設定收貨地址">
                <el-button type="danger" size="small" @click="addAddress">新增</el-button>
            </el-empty>
        </div>
        <RcvAddressDialog :dialogFormVisible="dialogFormVisible"
                       :editRow="editRow"
                       @resetEditRow="resetEditRow"
                       @dialogVisible="dialogVisible"
                       @refreshAddressList="getAddressList"
        ></RcvAddressDialog>

    </div>
</template>

<script>
    import {DelCityListById, GetAddressList} from '@/request/api.js';
    import RcvAddressDialog from '@/components/RcvAddressDialog.vue';

    export default {
        components: {RcvAddressDialog},
        data() {
            return {
                emptFlg:false,
                addressList: [],
                dialogFormVisible: false,
                editRow: {}
            }
        },
        methods: {
            delAddress(item) {
                this.$confirm('確定要删除嗎？')
                    .then(() => {
                        DelCityListById({id: item.id}).then(res => {
                            if (res.code == 200) {
                                this.$store.commit('SET_RCVADDRESS', {});
                                this.$message.success(res.message);
                                this.getAddressList();
                            } else {
                                this.$message.error(res.message);
                            }

                        }).catch(err => {
                            console.log(err);
                            this.$message.error('網絡請求失敗，稍候請重試!');
                        });
                    }).catch(() => {
                });
            },
            editAddress(item) {
                this.editRow = item;
                this.dialogFormVisible=true;
            },
            addAddress() {
                this.editRow={};
                this.dialogFormVisible = true;
                /*this.addressForm.id = 0;
                // this.$refs['ruleAddressForm'].resetFields();
                if (this.cityList.length <= 0) {
                    this.initAllCityList();
                }*/
            },
            getAddressList() {
                let _this = this;
                const loading = this.$loading({
                    lock: true,
                    text: "加載中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)"
                });
                GetAddressList({page: 1, limit: 10}).then(res => {
                    loading.close();
                    if (res.code == 200) {
                        this.addressList = res.data.list;
                        if (this.addressList.length <= 0) {
                            _this.emptFlg = true;
                        }else {
                            _this.emptFlg = false;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    loading.close();
                    console.error(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            dialogVisible(status){
                this.dialogFormVisible=status;
            },
            resetEditRow(){
                this.editRow={};
            }
        },
        mounted() {
            this.getAddressList();
        }
    }
</script>
<style lang="less" scoped>
    /deep/.el-button--mini {
        padding: 7px 5px;
    }

    .content{
        padding: 10px;
        font-size: 14px;
    }
</style>
