import { render, staticRenderFns } from "./RcvAddressDialog.vue?vue&type=template&id=463332d4&scoped=true&"
import script from "./RcvAddressDialog.vue?vue&type=script&lang=js&"
export * from "./RcvAddressDialog.vue?vue&type=script&lang=js&"
import style0 from "./RcvAddressDialog.vue?vue&type=style&index=0&id=463332d4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463332d4",
  null
  
)

export default component.exports